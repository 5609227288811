import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import EpicHeader from '../../assets/images/Epic_imagotipo-black--03.png'
import EpicImagotipo from '../../assets/images/Epic-imagotipo-horizontal-positivo(1)--03.png'
import EpicHeaderIsotipo from '../../assets/images/Epic-isotipogroup--03.png'
import YoutubeWhite from '../../assets/images/youtube--white.svg'
import TiktokWhite from '../../assets/images/tiktok--white.svg'
import TwitterWhite from '../../assets/images/twitter--white.svg'
import FacebookWhite from '../../assets/images/facebook--white.svg'
import InstagramWhite from '../../assets/images/instagram--white.svg'
import LinkedinWhite from '../../assets/images/linkedin--white.svg'
import WhatsappWhite from '../../assets/images/whatsapp--white.svg'
import EmailWhite from '../../assets/images/mail--white.svg'

import '../../assets/styles/components/buttons.scss'
import './header.scss'

const Header = ({ headerBackgroundColor, goInsideOption, plansOption, communityOption = 'active' }) => {
  const [ matchWidthMobile, setMatchWidthSize ] = useState(true)
  
  const VerifyResize = _ => {
    const matchWidth = window.matchMedia('(max-width: 1024px)')
    
    if (matchWidth.matches) {
      setMatchWidthSize(true)
    } else {
      setMatchWidthSize(false)
    }
  }
  document.addEventListener('resize', VerifyResize)


  const closeDropdown = _ => {
    if (document.getElementById('checkbox-header-dropdown').checked) {
      document.getElementById('checkbox-header-dropdown').checked = false
    }
  }

  useEffect(_ => {
    VerifyResize()
  }, [])

  return (
    <header className={`home-header ${headerBackgroundColor}`}>
      <div className={`header_content ${matchWidthMobile ? "" : "width-content"}`}>
        <a className="header__content__image-container" href="https://epicgrouplab.com" onClick={closeDropdown}>
          <img
            className="header__content__image"
            src={matchWidthMobile ? EpicHeaderIsotipo : EpicHeader}
            alt="header" />
        </a>

        <input type="checkbox" name="" id="home-header__burger-button__input" />
        <div className="home-header__burger-button-container">
          <label htmlFor="home-header__burger-button__input">
            <div className="home-header__burger-button">
              <div className="home-header__burger-button__line"></div>
              <div className="home-header__burger-button__line"></div>
              <div className="home-header__burger-button__line"></div>
            </div>
          </label>

          <nav className="home-header__navbar">
            <a
              className="text--dark-purple home-header__text"
              href="https://epicgrouplab.com/home-page"
              onClick={closeDropdown}>Home</a>
            <a
              className="text--dark-purple home-header__text"
              href="https://epicgrouplab.com/centers"
              onClick={closeDropdown}>Centros</a>
            <Link
              className="text--dark-purple home-header__text"
              to="/"
              onClick={closeDropdown}>Matricúlate</Link>
            <a
              className="text--dark-purple home-header__text"
              href="https://epicgrouplab.com/courses">Cursos</a>
            <a
              className="text--dark-purple home-header__text"
              href="https://epicgrouplab.com/demo-class"
              onClick={closeDropdown}>Contacto</a>
          </nav>

          <ul className="home-header__social-media-list">
            <li className="home-header__social-media-list__list-content">
              <a className="home-header__social-media-list__container" href="https://youtube.com/@epickidslab2940" target="_blank">
                <img className="home-header__social-media-list__icon" src={YoutubeWhite} alt="youtube" />
              </a>
            </li>
            <li className="home-header__social-media-list__list-content">
              <a
                className="home-header__social-media-list__container"
                href="https://www.tiktok.com/@epicgrouplab.puertovalla?_t=8dQ60hf14SQ&_r=1"
                target="_blank">
                <img className="home-header__social-media-list__icon" src={TiktokWhite} alt="tiktok" />
              </a>
            </li>
            <li className="home-header__social-media-list__list-content">
              <a
                className="home-header__social-media-list__container"
                href="https://twitter.com/EpickidsL?t=0Ci9GTYXj19Rf-Q_i2FBQA&s=08"
                target="_blank">
                <img className="home-header__social-media-list__icon" src={TwitterWhite} alt="twitter" />
              </a>
            </li>
            <li className="home-header__social-media-list__list-content">
              <a
                className="home-header__social-media-list__container"
                href=""
                target="_blank">
                <img className="home-header__social-media-list__icon" src={FacebookWhite} alt="facebook" />
              </a>
            </li>
            <li className="home-header__social-media-list__list-content">
              <a
                className="home-header__social-media-list__container"
                href=""
                target="_blank">
                <img className="home-header__social-media-list__icon" src={InstagramWhite} alt="instagram" />
              </a>
            </li>
            <li className="home-header__social-media-list__list-content">
              <a
                className="home-header__social-media-list__container"
                href="https://www.linkedin.com/company/epickids-lab/"
                target="_blank">
                <img className="home-header__social-media-list__icon" src={LinkedinWhite} alt="linkedin" />
              </a>
            </li>
            <li className="home-header__social-media-list__list-content">
              <a className="home-header__social-media-list__container" href="https://wa.me/+5213221006614" target="_blank">
                <img className="home-header__social-media-list__icon" src={WhatsappWhite} alt="whatsapp" />
              </a>
            </li>
            <li className="home-header__social-media-list__list-content">
              <a
              className="home-header__social-media-list__container"
              href="mailto:hola.puertovallarta.mx@epicgrouplab.com?subject=Mayor%20información"
              target="_blank">
                <img className="home-header__social-media-list__icon" src={EmailWhite} alt="email" />
              </a>
            </li>
          </ul>
        </div>

        <div className="header_content_buttons_container">
          <a
            className="button--second"
            href="https://campus.epicgrouplab.com"
            onClick={closeDropdown}>
              <p className="paragraph--highlight">Entrar</p>
          </a>
        </div>
      </div>
    </header>
  )
}

export default Header